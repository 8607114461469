import type { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import { QueryParams } from 'src/interface/utility';
import { objectEntries } from 'src/tools/object/objectEntries';
import { objectFromEntries } from 'src/tools/object/objectFromEntries';
import { buildTraceId } from 'src/tools/tracing/buildTraceId';
import { traceMap } from 'src/tools/tracing/constants';

interface RequestOptions<Body> {
  params?: QueryParams;
  body?: Body;
  token?: string;
}

export const prepareRequest = <Body>(
  url: string,
  { params, body, token }: RequestOptions<Body> = {},
) => {
  let serializedUrl = url;
  let serializedBody;
  const headers: HeadersInit = {};
  if (token) {
    const traceId = buildTraceId();
    traceMap.add(url, traceId);
    headers.traceparent = traceId;
    headers.Authorization = `Bearer ${token}`;
  }
  if (params) {
    const queryParams = params
      ? new URLSearchParams(params as Record<string, string>).toString()
      : '';

    if (queryParams !== '') {
      serializedUrl = `${serializedUrl}?${queryParams}`;
    }
  }
  if (body) {
    serializedBody = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }
  return {
    serializedUrl,
    headers,
    serializedBody,
  };
};

type ApiPathSets<
  PathSetName extends string,
  PathName extends string,
  PathParam,
> = {
  [pathSetName in PathSetName]: {
    [pathName in PathName]: (...args: PathParam[]) => string;
  };
};

export function convertToAbsolutePaths<
  PathSets extends ApiPathSets<PathSetName, PathName, PathParam>,
  PathSetName extends string,
  PathName extends string,
  PathParam,
  BaseUrl extends string,
>(baseUrl: BaseUrl, pathSets: PathSets) {
  return objectFromEntries(
    objectEntries(pathSets).map(([pathSetName, pathSet]) => [
      pathSetName,
      objectFromEntries(
        objectEntries(pathSet).map(([pathName, pathGenerator]) => [
          pathName,
          (...args: PathParam[]) => `${baseUrl}${pathGenerator(...args)}`,
        ]),
      ),
    ]),
  ) as {
    [Key in keyof PathSets]: {
      [Key2 in keyof PathSets[Key]]: (
        ...args: Parameters<PathSets[Key][Key2]>
      ) => `${BaseUrl}${ReturnType<PathSets[Key][Key2]>}`;
    };
  };
}

export const combineQueryStatuses = (
  ...statuses: DataLoadStatus[]
): DataLoadStatus =>
  statuses.every((status) => status === 'success')
    ? 'success'
    : statuses.some((status) => status === 'error')
    ? 'error'
    : 'pending';
