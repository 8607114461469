import { useCallback, useEffect } from 'react';
import { useUserProfileSetting } from 'src/contexts/profile-settings-context';

export const KNOWN_TOGGLES = [
  'ENABLE_DEV_MODE',
  'ENABLE_BEHAVIORS',
  'ENABLE_DEMO_DATA',
  'ENABLE_AV_MAP_LAYER',
  'ENABLE_SINGLE_BOOTUP',
  'ENABLE_NEW_DISPATCHES',
  'ENABLE_CONSTRAINT_EDITOR',
  'ENABLE_VEHICLE_SCHEDULING',
  'ENABLE_ZOOM_ON_BIG_SCREENS',
  'ENABLE_SINGLE_VEHICLE_CONFIG',
  'ENABLE_TABLE_KEYBOARD_NAVIGATION',
  'ENABLE_FLEET_MONITORING_HIDE_ERRORS',
  'ENABLE_FLEET_MONITORING_HIDE_PARTNERS',
] as const;
export type ToggleName = (typeof KNOWN_TOGGLES)[number];
export type ToggleOption = ToggleName | `!${ToggleName}`;

const USER_TOGGLES_ID = 'USER_TOGGLES';
const INITIAL_TOGGLES_RAW = JSON.parse(
  String(localStorage.getItem(USER_TOGGLES_ID)),
);
const INITIAL_TOGGLES = Array.isArray(INITIAL_TOGGLES_RAW)
  ? INITIAL_TOGGLES_RAW
  : null;

const isNegatedToggle = (
  toggleName?: ToggleOption,
): toggleName is `!${ToggleName}` => !!toggleName?.startsWith('!');

export const useGetHasToggle = () => {
  const { setting: _toggles } = useUserProfileSetting('toggles');
  const toggles = _toggles || INITIAL_TOGGLES;

  useEffect(
    function updateTOGGLES() {
      if (!toggles) return;

      localStorage.setItem(USER_TOGGLES_ID, JSON.stringify(toggles));
    },
    [toggles],
  );

  return useCallback(
    (toggleName?: ToggleOption) => {
      if (!toggleName || !toggles) {
        return null;
      }
      if (isNegatedToggle(toggleName)) {
        return !toggles.includes(toggleName.slice(1));
      } else {
        return toggles.includes(toggleName);
      }
    },
    [toggles],
  );
};

export const useHasToggle = (toggleName?: ToggleOption) => {
  const hasToggle = useGetHasToggle();

  return hasToggle(toggleName);
};
